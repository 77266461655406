import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { happinessSurveyPropType, itemSurveyPropType } from "components/propTypes";
import happinessSurveyValues, {
  optionalFitFeedBackInputOptions,
  overallFitFeedBackInputOptions,
  pages,
} from "constants/happiness-survey-values";

import InputContainer from "./inputs/input-container";
import SurveyControls from "components/source/happiness_survey/hs_reviews_combined/survey-controls/survey-controls";
import SurveyItemBanner from "components/source/happiness_survey/hs_reviews_combined/survey-item-banner";
import HappinessSurveyFooter from "components/source/happiness_survey/hs_reviews_combined/happiness-survey-footer";
import CapsuleSelect from "./inputs/capsule-select";
import AccordionContainer from "./inputs/accordion-container";

import HappinessSurveyStyles from "./happiness-survey.module.scss";

import { addFeedback } from "actions/happiness-survey-actions";
import { happinessSurveyContainsFeedback } from "helpers/feedback-helper";
import { convertCurrentSurveyIntoForm, isLocalValueSelected } from "helpers/happiness-survey-helpers";

const propTypes = {
  completeSurvey: PropTypes.func,
  currentSurvey: itemSurveyPropType,
  goToPage: PropTypes.func.isRequired,
  happinessSurvey: happinessSurveyPropType,
  canSnoozeAll: PropTypes.bool,
  closeHappinessSurvey: PropTypes.func,
};

const optionalFitFeedBackInputKeys = Object.keys(optionalFitFeedBackInputOptions);
const rateItInputKeys = [happinessSurveyValues.feedbackTypes.overallFit, ...optionalFitFeedBackInputKeys];
const defaultForm = rateItInputKeys.reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: "",
  }),
  {}
);

function DidntFit({
  completeSurvey,
  currentSurvey,
  goToPage,
  happinessSurvey,
  canSnoozeAll = false,
  closeHappinessSurvey = () => {},
}) {
  if (!currentSurvey || !currentSurvey.bookingId) {
    return null;
  }

  const isSubmittingSurvey = !happinessSurveyContainsFeedback(currentSurvey, "POOR_CONDITION");
  const [form, setForm] = React.useState(convertCurrentSurveyIntoForm(currentSurvey, defaultForm));
  const dispatch = useDispatch();

  const saveFeedbackToRedux = () => {
    for (const key in form) {
      const feedbackValuesMap = happinessSurveyValues[key];
      if (
        (!form[key] && happinessSurveyContainsFeedback(currentSurvey, Object.keys(feedbackValuesMap))) ||
        (form[key] && !happinessSurveyContainsFeedback(currentSurvey, form[key]))
      ) {
        // dispatching addFeedback does two things:
        // 1. with a declared type will remove all feedback related to that key
        // 2a. dispatching with a new value will add the feedback value to the survey
        // 2b. dispatching with a null value will do nothing
        dispatch(addFeedback(form[key], currentSurvey.bookingId, key, feedbackValuesMap));
      }
    }
  };

  const handleClickBack = () => {
    saveFeedbackToRedux();
    goToPage(pages.DIDNT_WEAR);
  };

  const handleClickNext = () => {
    saveFeedbackToRedux();
    if (isSubmittingSurvey) {
      completeSurvey();
    } else {
      // when we deprecate the old HS, let's replace this with happinessSurveyValues.negativeFeedback.POOR_CONDITION in SWAT-464
      goToPage(pages.QUALITY_ISSUES);
    }
  };

  const handleSelect = (value, type) => {
    setForm(oldForm => ({
      ...oldForm,
      [type]: oldForm[type] === value ? "" : value,
    }));
  };

  const isButtonDisabled = () => {
    return !Object.values(form).find(value => value);
  };

  const fitLabelProps = {
    headerText: "We're sorry to hear this! Why didn't it fit?",
  };

  const optionalFitLabelProps = {
    headerText: "Any other fit details to add?",
  };

  const footerProps = canSnoozeAll
    ? {
        showSecondaryCta: true,
        secondaryCtaText: "Save For Later",
        secondaryCtaDisabled: false,
        onClickSecondaryCta: closeHappinessSurvey,
      }
    : {};

  const footer = (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HappinessSurveyFooter onClick={handleClickNext} disabled={isButtonDisabled()} {...footerProps} />
  );

  return (
    <div className={HappinessSurveyStyles["happiness-survey__container"]}>
      <SurveyControls onClickBack={handleClickBack} onClickSkip={handleClickNext} />
      <div className={HappinessSurveyStyles["happiness-survey__body"]}>
        <SurveyItemBanner currentSurvey={currentSurvey} happinessSurvey={happinessSurvey} />
        <div className={HappinessSurveyStyles["happiness-survey__content"]}>
          <InputContainer labelProps={fitLabelProps}>
            <CapsuleSelect
              isOptionsOnly={true}
              onSelect={handleSelect}
              options={overallFitFeedBackInputOptions.map(option => ({
                ...option,
                selected: isLocalValueSelected(form, option.value, happinessSurveyValues.feedbackTypes.overallFit),
              }))}
              type={happinessSurveyValues.feedbackTypes.overallFit}
            />
          </InputContainer>

          <AccordionContainer
            showDisplay={true}
            labelProps={optionalFitLabelProps}
            additionalClassName={HappinessSurveyStyles["happiness-survey__other-fit-options-container"]}>
            {optionalFitFeedBackInputKeys.map(key => {
              const inputObject = optionalFitFeedBackInputOptions[key];
              const options = inputObject.options.map(option => ({
                ...option,
                selected: isLocalValueSelected(form, option.value, key),
              }));
              const labelProps = {
                headerText: inputObject.title,
              };

              return (
                <InputContainer labelProps={labelProps}>
                  <CapsuleSelect
                    isOptionsOnly={true}
                    key={key}
                    onSelect={handleSelect}
                    options={options}
                    type={key}
                    typeAsText={inputObject.title}
                  />
                </InputContainer>
              );
            })}
          </AccordionContainer>
        </div>
      </div>
      {footer}
    </div>
  );
}
DidntFit.pageName = happinessSurveyValues.pages.DIDNT_FIT;
DidntFit.propTypes = propTypes;

export default DidntFit;
