import React from "react";
import PropTypes from "prop-types";
import ActionLogger from "action-logger";
import { compose } from "redux";
import { connect } from "react-redux";

import { HappinessSurveyTypes } from "rtr-constants";
import MembershipHelpers from "helpers/membership-helpers";
import { REFERRAL_REMINDER_MODAL } from "components/source/membership/modals/SmartReferralsReminderModal";

import sharedActions from "actions/shared-actions";
import happinessSurveyActions from "actions/happiness-survey-actions";
import smartComponentActions from "actions/smart-component-actions";

import HappinessSurveySelfServiceExchangeModal from "./hs_self_service_exchange/hs-self-service-exchange";
import HSReviewsCombined from "./hs_reviews_combined/hs-reviews-combined";
import { status } from "constants/happiness-survey-values";

import {
  flagsAndExperimentsPropType,
  withOnLoadFlagsAndExperiments,
  flagsAndExperimentNames,
} from "components/source/hoc/with-flags-and-experiments";

import { isBefore } from "date-fns";

// TODO: Remove once flag is flipped off
import StorageHelperApi from "helpers/storage-helper";
const StorageHelper = StorageHelperApi.getApi("happinessSurvey", ["snooze"]);

export class HappinessSurveyContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    happinessSurvey: PropTypes.object,
    isHappinessSurveyFetched: PropTypes.bool,
    loadReferralsReminderModal: PropTypes.func,
    membershipState: PropTypes.object,
    flagsAndExperiments: flagsAndExperimentsPropType,
    smartComponentState: PropTypes.shape({
      didFetchSmartReferralsReminderModal: PropTypes.bool,
      referralsReminderModal: PropTypes.shape({
        name: PropTypes.string,
        CTA: PropTypes.string,
        body: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  };

  static completeStatus = "COMPLETE";
  static modalName = "HAPPINESS-SURVEY-MODAL";

  state = {
    surveyShownPixelSent: false,
    isModalOpen: true,
  };

  useOldSurveySkip = () =>
    this.props.flagsAndExperiments[flagsAndExperimentNames.SWAT_SF_SWAT_650_HAPPINESS_SURVEY_SKIP];

  // New functions to check if survey is snoozed on BE
  isSurveySnoozed = survey => !!survey?.snoozeExpiresOn;
  isSnoozeActive = survey => survey?.snoozeExpiresOn && isBefore(new Date(), survey.snoozeExpiresOn);
  isSnoozeExpired = survey => this.isSurveySnoozed(survey) && !this.isSnoozeActive(survey);
  allItemSurveysSnoozed() {
    const itemSurveys = this.props?.happinessSurvey?.itemSurveys ?? [];
    return itemSurveys.every(survey => this.isSnoozeActive(survey));
  }

  // Old functions to check if survey is snoozed in session storage
  // TODO: Remove once flag is flipped off
  getIsSurveySnoozed() {
    return StorageHelper.getItem("snooze");
  }

  componentDidUpdate(prevProps) {
    // check if we initialized the HS and should fire the pixel
    if (
      this.props.happinessSurvey &&
      !this.state.surveyShownPixelSent &&
      this.props.happinessSurvey?.itemSurveys?.length
    ) {
      ActionLogger.logAction({
        object_type: "happiness_survey",
        action: "shown",
      });
      this.setState({
        surveyShownPixelSent: true,
      });

      if (!prevProps.displayedModal) {
        this.props.dispatch(sharedActions.displayModal(this.constructor.modalName));
      }
    }

    if (!this.props.isHappinessSurveyFetched && MembershipHelpers.isActiveMembership(this.props.membershipState)) {
      this.props.dispatch(happinessSurveyActions.happinessSurveyFetched(true));
      this.props.dispatch(happinessSurveyActions.fetchHappinessSurveys());
    }

    const shouldFetchSurveys =
      this.props.happinessSurvey?.itemSurveys?.length > prevProps.happinessSurvey?.itemSurveys?.length;

    if (shouldFetchSurveys) {
      if (this.useOldSurveySkip() && this.getIsSurveySnoozed()) {
        StorageHelper.setItem("snooze", false);
      }
      this.props.dispatch(happinessSurveyActions.fetchHappinessSurveys());
    }

    if (
      !prevProps.smartComponentState?.didFetchSmartReferralsReminderModal &&
      this.props.smartComponentState?.didFetchSmartReferralsReminderModal &&
      this.props.smartComponentState.referralsReminderModal?.name &&
      this.props.smartComponentState.referralsReminderModal.name !== "noModal"
    ) {
      this.props.dispatch(sharedActions.displayModal(REFERRAL_REMINDER_MODAL));
    }
  }

  handleRequestClose = () => {
    // Product wants this non-closable modal to be closeable when clicking the overlay,
    // if all surveys are completed.
    const itemSurveysList = this.props?.happinessSurvey?.itemSurveys ?? [];
    const hasCompletedAllSurveys = itemSurveysList.every(s => s.status === this.constructor.completeStatus);

    if (hasCompletedAllSurveys && this.state.isModalOpen) {
      this.launchPostHappinessModals();
    }
  };

  launchPostHappinessModals() {
    const { loadReferralsReminderModal } = this.props;

    loadReferralsReminderModal();
  }

  currentSurvey = () => {
    const itemSurveys = this.props?.happinessSurvey?.itemSurveys;
    return itemSurveys.find(s => s.inProgress === true);
  };

  closeHappinessSurvey = () => {
    const { happinessSurvey, dispatch } = this.props;
    const itemSurveysList = happinessSurvey?.itemSurveys ?? [];
    const hasCompletedAllSurveys = itemSurveysList.every(survey => survey.status === this.constructor.completeStatus);

    this.setState({ isModalOpen: false });
    dispatch(sharedActions.displayModal(false));

    if (hasCompletedAllSurveys) {
      this.launchPostHappinessModals();
      return;
    }

    if (this.useOldSurveySkip()) {
      StorageHelper.setItem("snooze", true);
    } else {
      itemSurveysList
        .filter(survey => survey?.id && survey.status !== status.COMPLETE && !this.isSurveySnoozed(survey))
        .forEach(survey => dispatch(happinessSurveyActions.snoozeSurvey(survey)));
    }
  };

  render() {
    const happinessSurvey = this.props.happinessSurvey || {};
    const itemSurveys = happinessSurvey?.itemSurveys?.length;

    const allSurveysSnoozed = this.useOldSurveySkip() ? this.getIsSurveySnoozed() : this.allItemSurveysSnoozed();

    if (!itemSurveys || allSurveysSnoozed) {
      return null;
    }

    if (happinessSurvey.type !== HappinessSurveyTypes.RESERVE) {
      return (
        <HSReviewsCombined
          closeHappinessSurvey={this.closeHappinessSurvey}
          isSurveySnoozed={this.isSurveySnoozed}
          isSnoozeActive={this.isSnoozeActive}
          isSnoozeExpired={this.isSnoozeExpired}
          handleRequestClose={this.handleRequestClose}
          isModalOpen={this.state.isModalOpen}
          useOldSurveySkip={
            this.props.flagsAndExperiments[flagsAndExperimentNames.SWAT_SF_SWAT_650_HAPPINESS_SURVEY_SKIP]
          }
        />
      );
    } else {
      return (
        <HappinessSurveySelfServiceExchangeModal
          handleRequestClose={this.handleRequestClose}
          isModalOpen={this.state.isModalOpen}
        />
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dispatch: ownProps.dispatch,
    displayedModal: state.displayedModal,
    happinessSurvey: state.happinessSurvey,
    isHappinessSurveyFetched: state.isHappinessSurveyFetched,
    membershipState: MembershipHelpers.getMembershipState(state),
    smartComponentState: state.smartComponentState,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadReferralsReminderModal: () => smartComponentActions.getReferralsReminderModal()(dispatch),
    dispatch,
  };
};

export default compose(
  withOnLoadFlagsAndExperiments(flagsAndExperimentNames.SWAT_SF_SWAT_650_HAPPINESS_SURVEY_SKIP),
  connect(mapStateToProps, mapDispatchToProps)
)(HappinessSurveyContainer);
