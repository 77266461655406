import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { happinessSurveyPropType, itemSurveyPropType } from "components/propTypes";

import InputContainer from "./inputs/input-container";
import FitProfile from "./fit-profile/fit-profile";
import {
  FIT_PROFILE_KEY_NAMES,
  formatBirthday,
  isProfileValid,
  parseFitProfileForSubmission,
  parseUserProfileToFitProfile,
} from "./fit-profile/fit-profile-helpers";
import SurveyControls from "components/source/happiness_survey/hs_reviews_combined/survey-controls/survey-controls";
import SurveyItemBanner from "components/source/happiness_survey/hs_reviews_combined/survey-item-banner";
import HappinessSurveyFooter from "components/source/happiness_survey/hs_reviews_combined/happiness-survey-footer";
import Rating from "components/source/reviews/rating";
import PhotoUploaderInput from "components/source/happiness_survey/hs_reviews_combined/inputs/photo-uploader-input";

import { hasFileSizeError as hasFileSizeErrorAction } from "actions/shared-actions";
import { updateProfile, fetchProfile } from "actions/user-actions";
import { addSurveyRating, addSurveyCaption, addSurveyReviewContent } from "actions/happiness-survey-actions";
import HappinessSurveyStyles from "./happiness-survey.module.scss";
import InputStyles from "./inputs/inputs.module.scss";

import { pages, happinessSurveyCTACopy } from "constants/happiness-survey-values";

const propTypes = {
  handleCompleteSurvey: PropTypes.func,
  currentSurvey: itemSurveyPropType,
  goToPage: PropTypes.func,
  happinessSurvey: happinessSurveyPropType,
  isLastSurvey: PropTypes.bool,
  canSnoozeAll: PropTypes.bool,
  closeHappinessSurvey: PropTypes.func,
};

function Review({
  handleCompleteSurvey,
  currentSurvey,
  goToPage,
  happinessSurvey,
  isLastSurvey,
  canSnoozeAll = false,
  closeHappinessSurvey = () => {},
}) {
  const ReviewLabelRef = React.createRef(null);
  const HeadlineInputRef = React.createRef(null);
  if (!currentSurvey || !currentSurvey.bookingId || !currentSurvey.ratingMaps?.ratingMap) {
    return null;
  }

  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.userData?.userProfile) || {};
  const reviewPhotos = useSelector(state => state.reviewPhotos) || [];
  const loadingPhotos = useSelector(state => state.loadingPhotos) || false;
  const loadingRotatedPhoto = useSelector(state => state.loadingRotatedPhoto) || false;

  const [fitProfile, setFitProfile] = React.useState({});
  const [rating, setRating] = React.useState(currentSurvey.rating ?? 0);
  const [caption, setCaption] = React.useState(currentSurvey.caption ?? "");
  const [isAnonymous, setIsAnonymous] = React.useState(true);
  const [reviewContent, setReviewContent] = React.useState(currentSurvey.reviewContent ?? "");
  const [headlineErrorCopy, setHeadlineErrorCopy] = React.useState("Headline is required");
  const [hasFitProfileError, setHasFitProfileError] = React.useState(false);
  const [showEditFitProfileForm, setShowEditFitProfileForm] = React.useState(false);
  const requiredFields = [
    FIT_PROFILE_KEY_NAMES.DATE_OF_BIRTH,
    FIT_PROFILE_KEY_NAMES.HEIGHT,
    FIT_PROFILE_KEY_NAMES.PRIMARY_SIZE,
  ];

  React.useEffect(() => {
    const parsedFitProfile = parseUserProfileToFitProfile(userProfile);
    parsedFitProfile[FIT_PROFILE_KEY_NAMES.DATE_OF_BIRTH] = formatBirthday(
      parsedFitProfile[FIT_PROFILE_KEY_NAMES.DATE_OF_BIRTH]
    );
    const doesProfileHaveError = !isProfileValid(requiredFields, parsedFitProfile);
    setFitProfile(parsedFitProfile);
    setShowEditFitProfileForm(doesProfileHaveError);
    setHasFitProfileError(doesProfileHaveError);
    dispatch(hasFileSizeErrorAction(false));
  }, []);

  React.useEffect(() => {
    setFitProfile(parseUserProfileToFitProfile(userProfile));
  }, [userProfile]);

  React.useEffect(() => {
    const isAnon = !(caption || reviewPhotos.length > 0 || reviewContent || loadingPhotos || loadingRotatedPhoto);
    if (!isAnon) {
      if (reviewPhotos.length > 0 || loadingPhotos || loadingRotatedPhoto) {
        setHeadlineErrorCopy("Headline is required with photo");
      } else {
        setHeadlineErrorCopy("Headline is required");
      }
    }
    setIsAnonymous(isAnon);
  }, [caption, reviewPhotos, reviewContent, loadingPhotos, loadingRotatedPhoto]);

  const handleClickBack = () => {
    goToPage(pages.RATE_IT);
    saveContentToRedux();
  };

  React.useEffect(() => {
    if (!caption && !isAnonymous && document.activeElement !== HeadlineInputRef.current) {
      ReviewLabelRef.current.scrollIntoView({ behavior: "instant" });
    }
  }, [caption, isAnonymous, HeadlineInputRef.current]);

  const saveContentToRedux = () => {
    dispatch(addSurveyCaption(caption, currentSurvey.bookingId));
    dispatch(addSurveyReviewContent(reviewContent, currentSurvey.bookingId));
    dispatch(addSurveyRating(rating, currentSurvey.bookingId));
  };

  const handleSubmit = (options = {}) => {
    const saveSurvey = () => {
      saveContentToRedux();
      return handleCompleteSurvey(options);
    };
    // this deep comparison should work since parseUserProfileToFitProfile always renders the same object structure
    if (
      JSON.stringify(parseUserProfileToFitProfile(userProfile)) !==
      JSON.stringify(parseUserProfileToFitProfile(fitProfile))
    ) {
      const callBack = () => {
        return fetchProfile()(dispatch).then(() => saveSurvey());
      };
      dispatch(updateProfile(parseFitProfileForSubmission(fitProfile), callBack, callBack));
    } else {
      saveSurvey();
    }
  };

  const renderWornHappinessLevelLabels = rating => {
    if (rating in currentSurvey.ratingMaps.ratingMap) {
      return currentSurvey.ratingMaps.ratingMap[rating];
    } else {
      return "";
    }
  };

  const ratingLabelProps = {
    headerText: "What did you think?",
    isRequired: true,
  };
  const reviewLabelProps = {
    headerText: "Leave A Review (Optional)",
    subHeaderText: "Share your thoughts with the RTR community!",
    additionalClassName: { header: HappinessSurveyStyles["photo-uploader-title"] },
  };

  const REVIEW_RATING_VALUES = Object.keys(currentSurvey.ratingMaps.ratingMap).map(x => parseInt(x));
  const primaryCtaText = isLastSurvey
    ? happinessSurveyCTACopy.SUBMIT_LAST_SURVEY
    : happinessSurveyCTACopy.SUBMIT_SURVEY_AND_CONTINUE;

  const submissionButtonDisabled =
    rating < 1 || hasFitProfileError || (!isAnonymous && !caption) || loadingPhotos || loadingRotatedPhoto; // if the page is required, then the default must be a star rating

  const footerProps = canSnoozeAll
    ? {
        showSecondaryCta: true,
        secondaryCtaText: "Save For Later",
        secondaryCtaDisabled: false,
        onClickSecondaryCta: closeHappinessSurvey,
      }
    : {};

  return (
    <div className={HappinessSurveyStyles["happiness-survey__container"]}>
      <SurveyControls onClickBack={handleClickBack} onClickSkip={() => handleSubmit({ createReview: false })} />
      <div className={HappinessSurveyStyles["happiness-survey__body"]} data-test-id="happiness-survey-review-screen">
        <SurveyItemBanner currentSurvey={currentSurvey} happinessSurvey={happinessSurvey} />
        <div className={HappinessSurveyStyles["happiness-survey__content"]} ref={ReviewLabelRef}>
          <InputContainer labelProps={reviewLabelProps}>
            <PhotoUploaderInput product={currentSurvey?.product} />
          </InputContainer>
          <InputContainer labelProps={ratingLabelProps}>
            <div className={InputStyles["star-rating__container"]}>
              <Rating
                className={InputStyles["star-rating__input"]}
                defaultValue={rating}
                values={REVIEW_RATING_VALUES}
                onSelectRating={rating => setRating(rating)}
              />
              {rating > 0 && (
                <span className={InputStyles["star-rating__value-label"]}>
                  {renderWornHappinessLevelLabels(rating)}
                </span>
              )}
            </div>
          </InputContainer>
          <input
            className={`${InputStyles["text-field"]} ${!caption && !isAnonymous && InputStyles["error"]}`}
            maxLength="100"
            type="text"
            placeholder={!caption && !isAnonymous ? headlineErrorCopy : "Headline"}
            value={caption}
            onChange={e => setCaption(e.target.value)}
            ref={HeadlineInputRef}
          />
          <textarea
            maxLength="2000"
            rows="4"
            value={reviewContent}
            placeholder="Tell the RTR community how it fit, where you wore it, or how you styled it."
            onChange={e => setReviewContent(e.target.value)}
          />
          <div className={HappinessSurveyStyles["happiness-survey__divider"]} />
          <FitProfile
            fitProfile={fitProfile}
            setFitProfile={setFitProfile}
            showEditForm={showEditFitProfileForm}
            setHasFitProfileError={setHasFitProfileError}
          />
        </div>
      </div>
      <HappinessSurveyFooter
        primaryCtaText={primaryCtaText}
        onClick={handleSubmit}
        disabled={submissionButtonDisabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...footerProps}
      />
    </div>
  );
}
Review.pageName = pages.REVIEW;
Review.propTypes = propTypes;

export default Review;
