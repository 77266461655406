import PropTypes from "prop-types";

import { useDispatch } from "react-redux";

import { addFeedback, removeFeedback } from "actions/happiness-survey-actions";
import { happinessSurveyPropType, itemSurveyPropType } from "components/propTypes";
import { feedbackTypes, negativeFeedbackOptionsWithCopy, pages, wornInquiry } from "constants/happiness-survey-values";

import SurveyControls from "./survey-controls/survey-controls";
import SurveyItemBanner from "./survey-item-banner";
import HappinessSurveyFooter from "./happiness-survey-footer";
import InputContainer from "./inputs/input-container";
import ButtonContainer from "./inputs/button-container";

import HappinessSurveyStyles from "./happiness-survey.module.scss";

// when we deprecate the old HS, let's replace this with happinessSurveyValues.negativeFeedback.[key] in SWAT-464
const negativeFeedbackOptionsKeys = Object.keys(negativeFeedbackOptionsWithCopy).reduce(
  (prev, curr) => ({ ...prev, [curr]: curr }),
  {}
);

const propTypes = {
  completeSurvey: PropTypes.func,
  currentSurvey: itemSurveyPropType,
  goToPage: PropTypes.func,
  happinessSurvey: happinessSurveyPropType,
  hasSubmittedFirstSurvey: PropTypes.bool,
  canSnoozeAll: PropTypes.bool,
  closeHappinessSurvey: PropTypes.func,
};

const DidntWear = ({
  completeSurvey,
  currentSurvey,
  goToPage = () => {},
  happinessSurvey,
  hasSubmittedFirstSurvey,
  canSnoozeAll,
  closeHappinessSurvey = () => {},
}) => {
  if (!currentSurvey) {
    return null;
  }
  const dispatch = useDispatch();
  const selectedButtons = new Set();
  currentSurvey?.surveyFeedbacks?.forEach(fbEntry => {
    if (fbEntry.feedback in negativeFeedbackOptionsWithCopy) {
      selectedButtons.add(fbEntry.feedback);
    }
  });

  // if we return nothing, we hide the back button
  const handleClickBack = () => {
    if (!hasSubmittedFirstSurvey) {
      return () => goToPage(pages.START);
    } else {
      return;
    }
  };

  const handleClickNext = () => {
    dispatch(addFeedback(wornInquiry.DIDNT_WEAR_IT, currentSurvey.bookingId, feedbackTypes.wornInquiry));
    if (selectedButtons.has(negativeFeedbackOptionsKeys.DIDNT_FIT)) {
      goToPage(pages.DIDNT_FIT);
    } else if (selectedButtons.has(negativeFeedbackOptionsKeys.POOR_CONDITION)) {
      goToPage(pages.QUALITY_ISSUES);
    } else {
      completeSurvey();
    }
  };

  const inputLabelProps = {
    headerText: "Why didn\u2019t you wear it?",
    subHeaderText: "Select all that apply",
    isRequired: true,
  };

  const handleClickSelectionButton = data => {
    if (!selectedButtons.has(data)) {
      dispatch(addFeedback(data, currentSurvey.bookingId));
    } else {
      dispatch(removeFeedback(data, currentSurvey.bookingId));
    }
  };

  const getButtons = () => {
    return (
      <ButtonContainer
        selectedButtons={selectedButtons}
        buttons={negativeFeedbackOptionsWithCopy}
        hasFullWidthButtons={false}
        onClickSelectionButton={data => handleClickSelectionButton(data)}
      />
    );
  };

  const footerProps = canSnoozeAll
    ? {
        showSecondaryCta: true,
        secondaryCtaText: "Save For Later",
        secondaryCtaDisabled: false,
        onClickSecondaryCta: closeHappinessSurvey,
      }
    : {};

  const submissionButtonDisabled = selectedButtons.size === 0;
  const footer = (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HappinessSurveyFooter onClick={handleClickNext} disabled={submissionButtonDisabled} {...footerProps} />
  );

  return (
    <div className={HappinessSurveyStyles["happiness-survey__container"]}>
      <SurveyControls onClickBack={handleClickBack()} />
      <div
        className={HappinessSurveyStyles["happiness-survey__body"]}
        data-test-id="happiness-survey-didnt-wear-screen">
        <SurveyItemBanner currentSurvey={currentSurvey} happinessSurvey={happinessSurvey} />
        <div className={HappinessSurveyStyles["happiness-survey__content"]}>
          <InputContainer labelProps={inputLabelProps}>{getButtons()}</InputContainer>
        </div>
      </div>
      {footer}
    </div>
  );
};

DidntWear.pageName = pages.DIDNT_WEAR;
DidntWear.propTypes = propTypes;

export default DidntWear;
